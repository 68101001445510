import { useEffect, useState } from 'react'
import { off, on } from '../utils'
/**
 * useScrollingUp
 * @returns {boolean}
 */
const useScrollingUp = () => {
  let prevScroll
  //if it is SSR then check you are now on the client and window object is available
 
  const [scrollingUp, setScrollingUp] = useState(false)
  const handleScroll = () => {
    const currScroll = window.scrollY
    console.log(currScroll)
    var isScrolled = false;
    if(currScroll >= 400){
       isScrolled = true;
    }else{
        isScrolled = false;
    }
    setScrollingUp(isScrolled)

    prevScroll = currScroll
  }
  useEffect(() => {
    on(window, 'scroll', handleScroll, { passive: true })
    return () => {
      off(window, 'scroll', handleScroll, { passive: true })
    }
  }, [])
  return scrollingUp
}

export default useScrollingUp